import {
  AtSymbolIcon,
  Bars2Icon,
  Bars3BottomLeftIcon,
  ChevronDownIcon,
  CurrencyEuroIcon,
  HashtagIcon,
  LinkIcon,
  MapPinIcon,
  NoSymbolIcon,
  NumberedListIcon,
  PhoneIcon,
  ScaleIcon,
} from "@heroicons/react/24/outline";
import { Dot, Clock, Calendar, CalendarClock, LetterText } from "lucide-react";
import { FormFields } from "../models/form";

export enum FormFieldType {
  email = "email",
  phoneNumber = "phoneNumber",
  address = "address",
  website = "website",
  text = "text",
  longText = "longText",
  multipleChoice = "multipleChoice",
  dropdown = "dropdown",
  yesNo = "yesNo",
  legal = "legal",
  moneyField = "moneyField",
  decimal = "decimal",
  number = "number",
  date = "date",
  time = "time",
  dateAndTime = "dateAndTime",
  textContent = "textContent",
}

export const FORM_FIELDS: FormFields = {
  Contact: {
    name: "Contact",
    colour: "bg-yellow-400",
    fields: [
      {
        name: "email",
        label: "Email",
        type: FormFieldType.email,
        icon: AtSymbolIcon,
      },
      {
        name: "phoneNumber",
        label: "Phone Number",
        type: FormFieldType.phoneNumber,
        icon: PhoneIcon,
      },
      {
        name: "address",
        label: "Address",
        type: FormFieldType.address,
        icon: MapPinIcon,
      },
      {
        name: "website",
        label: "Website",
        type: FormFieldType.website,
        icon: LinkIcon,
      },
    ],
  },
  Text: {
    name: "Text",
    colour: "bg-blue-400",
    fields: [
      {
        name: "text",
        label: "Text",
        type: FormFieldType.text,
        icon: Bars2Icon,
      },
      {
        name: "longText",
        label: "Long Text",
        type: FormFieldType.longText,
        icon: Bars3BottomLeftIcon,
      },
    ],
  },
  Choice: {
    name: "Choice",
    colour: "bg-green-400",
    fields: [
      {
        name: "multipleChoice",
        label: "Multiple Choice",
        type: FormFieldType.multipleChoice,
        icon: NumberedListIcon,
      },
      {
        name: "dropdown",
        label: "Dropdown",
        type: FormFieldType.dropdown,
        icon: ChevronDownIcon,
      },
      {
        name: "yesNo",
        label: "Yes/No",
        type: FormFieldType.yesNo,
        icon: NoSymbolIcon,
      },
      {
        name: "legal",
        label: "Legal",
        type: FormFieldType.legal,
        icon: ScaleIcon,
      },
    ],
  },
  Number: {
    name: "Number",
    colour: "bg-red-400",
    fields: [
      {
        name: "moneyField",
        label: "Money Field",
        type: FormFieldType.moneyField,
        icon: CurrencyEuroIcon,
      },
      {
        name: "decimal",
        label: "Decimal",
        type: FormFieldType.decimal,
        icon: Dot,
      },
      {
        name: "number",
        label: "Number",
        type: FormFieldType.number,
        icon: HashtagIcon,
      },
    ],
  },
  Time: {
    name: "Time",
    colour: "bg-purple-400",
    fields: [
      {
        name: "date",
        label: "Date",
        type: FormFieldType.date,
        icon: Calendar,
      },
      {
        name: "time",
        label: "Time",
        type: FormFieldType.time,
        icon: Clock,
      },
      {
        name: "dateAndTime",
        label: "Date and Time",
        type: FormFieldType.dateAndTime,
        icon: CalendarClock,
      },
    ],
  },
  Content: {
    name: "Content",
    colour: "bg-orange-400",
    fields: [
      {
        name: "text",
        label: "Text",
        type: FormFieldType.textContent,
        icon: LetterText,
      },
    ],
  },
};
