import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { TicketQuantity } from "./TicketQuantity";
import { Ticket as TicketModel, TicketType } from "../models/Tickets";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../App";
import { CartContextType, CartItem, PromoCodeType } from "../models/Cart";
import { useSelector } from "react-redux";
import { State } from "../store/store";
import { calculateDiscount } from "../utils/cart";
import { format, parse, parseISO } from "date-fns";
import { Badge } from "./ui/badge";

interface Props {
  ticket: TicketModel;
}

export const Ticket = ({ ticket }: Props) => {
  const cart = useSelector((state: State) => state.cart.cart);
  const [isInCart, setIsInCart] = useState(false);
  const appliedPromoCode = useSelector(
    (state: State) => state.cart.appliedPromoCode
  );
  const [showFullDescription, setShowFullDescription] = useState(false);
  // Toggle function to show/hide the full description
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (cart) {
      const cartItem = cart.findIndex(
        (cartItem: CartItem) => ticket.uuid === cartItem.ticketUuid
      );
      setIsInCart(cartItem > -1);
    }
  }, [cart]);

  // Function to render the description
  const renderDescription = () => {
    if (ticket.description) {
      if (ticket.description.length > 50) {
        return (
          <div className="text-sm w-2/3 dark:text-gray-400">
            {showFullDescription
              ? `${ticket.description} `
              : `${ticket.description.substring(0, 65)}... `}
            <button
              onClick={toggleDescription}
              className="text-blue-500 hover:text-blue-700"
            >
              {showFullDescription ? "less info" : "more info"}
            </button>
          </div>
        );
      }
      return (
        <div className="text-sm w-2/3 dark:text-gray-400">
          {ticket.description}
        </div>
      );
    }
  };

  return (
    <li
      key={ticket.uuid}
      className={`p-5 border dark:border-white/5 shadow-sm mb-6 rounded-lg dark:bg-dark-secondary ${
        isInCart ? "border-primary dark:border-indigo-600" : ""
      }`}
    >
      <div className="flex">
        <div className="flex items-center sm:items-start flex-1">
          <div className="flex-1 text-sm">
            <div className="font-medium text-gray-900 flex justify-between">
              <h4 className="scroll-m-20 text-xl font-semibold tracking-tight dark:text-white">
                {ticket.name}
              </h4>
            </div>
            {ticket.onSaleTo && (
              <p className="text-sm text-muted-foreground dark:text-gray-500">
                {" "}
                Sale ends on&nbsp;
                {format(parseISO(ticket.onSaleTo), "dd LLL yyyy")}
              </p>
            )}
          </div>
        </div>
        <div className="">
          {ticket.isSoldOut ? (
            <span className="inline-flex items-center rounded-full bg-gray-100 dark:bg-dark-primary dark:text-white px-3 py-0.5 text-sm font-medium text-gray-800 text-center">
              Sold Out
            </span>
          ) : (
            <>
              {ticket.isTicketOnSale ? (
                <TicketQuantity ticket={ticket} />
              ) : (
                <div className="-mt-1">
                  <span className="inline-flex items-center rounded-full bg-gray-100 dark:bg-dark-primary dark:text-white px-3 py-0.5 text-sm font-medium text-gray-800 text-center">
                    Not on sale
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="mt-0 xs:flex mt-2 xs:mt-0">
        <div className="flex items-center flex-1">
          <div className="flex items-baseline gap-x-1 mt-1">
            <span className="text-lg tracking-tight text-gray-900 dark:text-white">
              {appliedPromoCode?.codeType === PromoCodeType.DISCOUNT &&
              appliedPromoCode.ticket.uuid === ticket.uuid ? (
                <>
                  <s>&euro;{ticket.price}</s>
                  <>
                    &nbsp; &euro;
                    {(
                      parseFloat(ticket.price) -
                      calculateDiscount(appliedPromoCode)
                    ).toFixed(2)}
                  </>
                  <span className="ml-2 text-sm font-semibold leading-6 text-gray-600">
                    + &euro;{ticket.fee} fee
                  </span>
                </>
              ) : (
                <div className="flex items-center">
                  {ticket.ticketType == TicketType.PAYMENT_PLAN ? (
                    <h3 className="font-semibold">
                      {ticket.numberOfMonths} monthly payments of &euro;
                      {ticket.perMonthPrice}
                    </h3>
                  ) : (
                    <h3 className="font-semibold">&euro;{ticket.price}</h3>
                  )}
                  {ticket.fee != "0.00" && (
                    <p className="text-sm text-muted-foreground ml-1.5">
                      {" "}
                      + &euro;{ticket.fee} fee
                    </p>
                  )}
                </div>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-0.5">{renderDescription()}</div>
    </li>
  );
};
