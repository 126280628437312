import axios, { AxiosResponse } from "axios";
import {
  AddonCartItem,
  CartItem,
  CartItemAdd,
  Event,
  PromoCode,
} from "./models/Cart";
import {
  CreateOrderRequest,
  ModifyPaymentMethodResponse,
  Order,
  OrderBase,
  OrderResponse,
} from "./models/Order";
import { Ticket } from "./models/Tickets";
import { FormResponse } from "./models/form";

axios.defaults.withCredentials = true;

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const getTickets = (): Promise<AxiosResponse<Array<Ticket>>> => {
  return axios.get(`${BASE_URL}/tickets`);
};

export const createOrder = (
  order: Order
): Promise<AxiosResponse<OrderResponse>> => {
  return axios.post(`${BASE_URL}/order`, order);
};

export const createAddonOrder = (
  orderUuid: string,
  addonCart: Array<AddonCartItem>
): Promise<AxiosResponse<OrderResponse>> => {
  return axios.post(`${BASE_URL}/order/${orderUuid}/addons`, {
    addons: addonCart,
  });
};

export const createPaymentPlan = (
  order: Order
): Promise<AxiosResponse<OrderResponse>> => {
  return axios.post(`${BASE_URL}/payment-plan`, order);
};

export const getPromoCode = (
  promoCode: string
): Promise<AxiosResponse<PromoCode>> => {
  return axios.get(`${BASE_URL}/promo-code/${promoCode}`);
};

export const getOrderByGatewayId = (
  gatewayId: string
): Promise<AxiosResponse<OrderBase>> => {
  return axios.get(`${BASE_URL}/order?gateway_id=${gatewayId}`);
};

export const getEvent = (
  organisationDomain: string,
  eventSlug: string
): Promise<AxiosResponse<Event>> => {
  return axios.get(`${BASE_URL}/event`, {
    headers: { OrganisationDomain: organisationDomain, EventSlug: eventSlug },
  });
};

export const getOrder = (
  orderUuid: string
): Promise<AxiosResponse<OrderBase>> => {
  return axios.get(`${BASE_URL}/order?uuid=${orderUuid}`);
};

export const getSetupIntent = (
  orderUuid: string
): Promise<AxiosResponse<ModifyPaymentMethodResponse>> => {
  return axios.get(`${BASE_URL}/payment-plan/modify/${orderUuid}`);
};

export const updatePaymentPlanPaymentMethod = (
  orderUuid: string,
  paymentMethodId: any
): Promise<AxiosResponse> => {
  return axios.post(`${BASE_URL}/payment-plan/modify/${orderUuid}`, {
    paymentMethodId,
  });
};

// export const addToCart = (
//   cartItemAdd: CartItemAdd
// ): Promise<AxiosResponse<Array<CartItem>>> => {
//   return axios.put('http://0.0.0.0:8000/cart', cartItemAdd);
// };

export const createOrderEmailLink = (
  orderNumber: string
): Promise<AxiosResponse> => {
  return axios.post(`${BASE_URL}/order/${orderNumber}/email-link`);
};

export const validateToken = (token: string): Promise<AxiosResponse> => {
  return axios.get(`${BASE_URL}/order/validate-token?token=${token}`);
};

export const getOrganisation = (): Promise<AxiosResponse> => {
  return axios.get(`${BASE_URL}/organisation`, {
    // headers: {
    //   OrganisationDomain: domain,
    // },
  });
};

export const createManualOrder = (
  createOrderRequest: CreateOrderRequest
): Promise<AxiosResponse> => {
  return axios.post(`${BASE_URL}/dashboard/orders/create`, createOrderRequest);
};

export const getForm = (
  formId: string
): Promise<AxiosResponse<FormResponse>> => {
  return axios.get(`${BASE_URL}/forms/${formId}`);
};
