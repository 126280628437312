import { Link } from "react-router-dom";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import { List } from "../../../crud/List";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  PresentationChartLineIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const actions = [
  {
    title: "Velocity Report",
    href: "/dashboard/reporting/velocity",
    icon: PresentationChartLineIcon,
    description:
      "View ticket sales from a higher level, week by week, day by day level and compare it to previous events",
    iconForeground: "text-teal-700 dark:text-teal-500",
    iconBackground: "bg-teal-50 dark:bg-teal-500/10",
  },
  {
    title: "Coming Soon",
    href: "",
    description:
      "We are working to add reports that will help you understand your event better. Check back soon!",
    icon: CheckBadgeIcon,
    iconForeground: "text-purple-700 dark:text-purple-500",
    iconBackground: "bg-purple-50 dark:bg-purple-500/10",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Reporting = () => {
  return (
    <DashboardLayout requiredPermissions={["view_user"]} pageTitle="Reporting">
      <div className="p-2">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 p-4">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0
                  ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                  : "",
                actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                actionIdx === actions.length - 1
                  ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                  : "",
                "group relative bg-white dark:bg-zinc-800 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "inline-flex rounded-lg p-3"
                  )}
                >
                  <action.icon aria-hidden="true" className="size-6" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold text-gray-900 dark:text-white">
                  <Link to={action.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span aria-hidden="true" className="absolute inset-0" />
                    {action.title}
                  </Link>
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  {action.description}
                </p>
              </div>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400 dark:text-gray-400"
              >
                <svg fill="currentColor" viewBox="0 0 24 24" className="size-6">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};
