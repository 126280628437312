import { Link } from "react-router-dom";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import { List } from "../../../crud/List";
import {
  AcademicCapIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ClockIcon,
  PresentationChartLineIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "react-query";
import { AxiosResponse } from "axios";
import { getVelocityReport } from "@/dashboardQueries";
import {
  DayData,
  DayToView,
  MonthlyReport,
  Order,
  VelocityReport as VelocityReportModel,
  WeeklyReport,
} from "@/models/Reports";
import { Fragment, useEffect, useState } from "react";
import { isPast, isSameWeek, parse, parseISO, set, setDay } from "date-fns";
import { useSelector } from "react-redux";
import { State } from "@/store/store";
import { EVENT_KEY } from "@/pages/protected-route";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { formatDateWithoutOrdinal } from "@/utils/date";
import { formatNumberToMoney } from "@/utils/cart";

export const VelocityReport = () => {
  const events = useSelector((state: State) => state.dashboard.events);
  const [report, setReport] = useState<VelocityReportModel | null>(null);
  const [comparisonYear, setComparisonYear] = useState<string>("");
  const [comparisonEvent, setComparisonEvent] = useState<string>("");
  const selectedEvent = JSON.parse(localStorage.getItem(EVENT_KEY) || "{}");
  const [dayToView, setDayToView] = useState<DayToView | null>(null);
  const [dayViewerOpen, setDayViewerOpen] = useState(false);

  const fetchReport = async (comparisonEvent?: string) => {
    const response = await getVelocityReport(comparisonEvent);
    setReport(response.data);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    if (report?.comparisonEvent) {
      setComparisonYear(
        parseISO(report.comparisonEvent?.startDate).getFullYear().toString()
      );
    }
  }, [report]);

  const handleComparisonEventChange = async (eventId: string) => {
    setComparisonEvent(eventId);
    await fetchReport(eventId);
  };

  /**
   * Calculates the percentage change between a comparison value and a base value.
   * @param comparison - The weekly count value for the comparison event.
   * @param base - The weekly count value for the base event.
   * @returns A formatted string showing the percentage change (e.g., "+50%", "-20%", "0%").
   */
  function calculatePercentageChange(comparison: number, base: number) {
    if (base === 0) {
      // If base is zero, handle edge cases
      return comparison > 0 ? "+∞%" : "0%";
    }

    const percentageChange = ((comparison - base) / base) * 100;
    let className = "w-full h-full flex items-center justify-center";

    if (percentageChange > 0) {
      className +=
        " bg-green-50 text-green-700 dark:bg-green-500/10 dark:text-green-500";
    } else if (percentageChange < 0) {
      className +=
        " bg-red-50 text-red-700 dark:bg-red-500/10 dark:text-red-500";
    }

    return (
      <div className={className}>
        {percentageChange > 0 ? "+" : ""}
        {percentageChange.toFixed(2)}%
      </div>
    );
  }

  function getDayAndMonthFromDate(date: string): string {
    const parsedDate = parseISO(date);
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");
    return `${month}-${day}`;
  }

  const today = new Date();

  const handleDayViewerClose = () => {
    setDayViewerOpen(false);
    setTimeout(() => {
      setDayToView(null);
    }, 750);
  };

  return (
    <DashboardLayout
      requiredPermissions={["view_user"]}
      pageTitle="Velocity Report"
    >
      <Transition.Root show={dayViewerOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleDayViewerClose}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl border-l dark:border-white/5">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl dark:bg-dark-primary border-l border-transparent dark:border-white/5">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6 dark:bg-dark-secondary">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                Orders on{" "}
                                {dayToView &&
                                  dayToView.date &&
                                  formatDateWithoutOrdinal(dayToView.date)}
                              </Dialog.Title>
                              {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                                    Get started by filling in the information
                                    below to create your new project.
                                  </p> */}
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="relative text-gray-400 hover:text-gray-500"
                                onClick={handleDayViewerClose}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Create Component */}
                        <div className="h-auto px-6">
                          {dayToView && dayToView?.day.orders.length > 0 ? (
                            <table className="min-w-full divide-y divide-gray-200 dark:divide-white/5">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                  >
                                    Buyer
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white"
                                  >
                                    Total
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold dark:text-white"
                                  >
                                    Order
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                                {dayToView.day.orders.map((order: Order) => (
                                  <tr key={order.uuid}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                      <div className="text-sm leading-6 text-gray-700 dark:text-white">
                                        {order.customerName}
                                      </div>
                                      <div className="mt-1 text-xs leading-5 text-gray-500 flex items-center dark:text-gray-400">
                                        {order.monthlyTotal ? (
                                          <ArrowPathIcon className="w-3.5 h-3.5 mr-1.5" />
                                        ) : (
                                          <CheckIcon className="w-3.5 h-3.5 mr-1.5" />
                                        )}
                                        {order.monthlyTotal
                                          ? "Payment Plan"
                                          : "Once Off"}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm dark:text-gray-300 text-gray-500">
                                      {formatNumberToMoney(order.amount)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                      <div className="flex justify-start">
                                        <Link
                                          to={`/dashboard/orders/${order.id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500 flex items-center"
                                        >
                                          View order{" "}
                                          <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2" />
                                        </Link>
                                      </div>
                                      <div className="mt-1 text-xs leading-5 text-gray-500 dark:text-gray-400 hidden lg:block">
                                        Order{" "}
                                        <span className="text-gray-900 dark:text-gray-400">
                                          #{order.uuid}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center h-96 flex items-center justify-center">
                              <div className="text-center">
                                <div className="flex items-center justify-center">
                                  <BanknotesIcon className="h-12 w-12 text-gray-300" />
                                </div>
                                <h3 className="mt-2 font-semibold text-gray-900 dark:text-white">
                                  No orders on this day
                                </h3>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex">
        <div className="flex flex-1 items-center justify-center">
          {!report ? (
            <div className="flex items-center justify-center h-screen w-full">
              <div>
                <div className="flex items-center justify-center -mt-44">
                  <svg
                    className="animate-spin h-20 w-20 dark:text-white mb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <h1 className="dark:text-white text-3xl font-semibold text-gray-700">
                  Just a moment while we crunch some numbers
                </h1>
              </div>
            </div>
          ) : (
            <>
              {report && report.baseEvent ? (
                <div className="mt-2">
                  <div className="flex justify-end mr-4 mt-2">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-zinc-800 dark:text-white dark:ring-white/10 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-dark-primary">
                          Select an event to compare to
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 size-5 text-gray-400"
                          />
                        </Menu.Button>
                      </div>

                      <Menu.Items
                        // transition
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-800 dark:text-white dark:ring-white/10 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          {events
                            ?.filter(
                              (event: any) => event.uuid != selectedEvent.uuid
                            )
                            .map((event: any) => (
                              <Menu.Item>
                                <button
                                  onClick={() =>
                                    handleComparisonEventChange(event.uuid)
                                  }
                                  className="flex block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                >
                                  {event.name}
                                  {comparisonEvent === event.uuid && (
                                    <CheckCircleIcon className="dark:text-gray-300 h-5 w-5 ml-2" />
                                  )}
                                </button>
                              </Menu.Item>
                            ))}
                        </div>
                      </Menu.Items>
                    </Menu>
                  </div>
                  <div className="p-4">
                    {report.comparisonEvent && (
                      <div className="flex text-white border-l border-transparent">
                        <div className="w-40 border-x border-transparent"></div>
                        <div className="w-24"></div>
                        <div
                          className={`dark:text-white bg-zinc-300 text-zinc-800 uppercase text-xs flex items-center justify-center text-center dark:bg-zinc-800 border-x border-t rounded-t-lg border-white/10 ${
                            report.comparisonEvent ? "w-64" : "w-36"
                          }`}
                        >
                          Weekly Total
                        </div>

                        {[
                          "Mon",
                          "Tues",
                          "Weds",
                          "Thurs",
                          "Fri",
                          "Sat",
                          "Sun",
                        ].map((day) => (
                          <div className="dark:text-white text-center py-2 border-r border-transparent dark:bg-primary-dark border-t items-center justify-center text-white font-semibold flex">
                            <div className="has-tooltip w-16"></div>
                          </div>
                        ))}

                        <div
                          className={`dark:text-white bg-zinc-300 text-zinc-800 uppercase text-xs text-center dark:bg-zinc-800 py-2 border-r border-white/10 border-t rounded-t-lg border-white/10 ${
                            report.comparisonEvent ? "w-64" : "w-36"
                          }`}
                        >
                          Cumulative Total
                        </div>
                      </div>
                    )}
                    <div className="flex dark:text-gray-300 border-transparent border-x">
                      <div className="w-40"></div>
                      <div className="dark:text-white border-l rounded-tl-lg bg-zinc-300 text-zinc-800 dark:bg-zinc-800 w-24 text-center flex justify-center items-center dark:border-white/10 bg-dark-primary border-t text-white font-semibold">
                        Weeks Left
                      </div>
                      <div
                        className={`dark:text-white text-center bg-zinc-300 text-zinc-800 dark:bg-zinc-800 dark:border-white/10 bg-dark-primary py-1 border-l border-r flex items-center justify-center border-t text-white font-semibold ${
                          report.comparisonEvent ? "w-64" : "w-36"
                        }`}
                      >
                        {report.comparisonEvent ? (
                          <div className="flex items-center px-3">
                            <div className="w-1/3">
                              {report.comparisonEvent.eventName}
                            </div>
                            <div className="w-1/3">%</div>
                            <div className="w-1/3">{selectedEvent.name}</div>
                          </div>
                        ) : (
                          "Weekly Total"
                        )}
                      </div>
                      {[
                        "Mon",
                        "Tues",
                        "Weds",
                        "Thurs",
                        "Fri",
                        "Sat",
                        "Sun",
                      ].map((day) => (
                        <div className="dark:text-white bg-zinc-300 text-zinc-800 dark:bg-zinc-800 text-center py-2 dark:border-white/10 border-r dark:bg-primary-dark border-t dark:bg-dark-primary items-center justify-center text-white font-semibold flex">
                          <div className="has-tooltip w-16">{day}</div>
                        </div>
                      ))}
                      <div
                        className={`${
                          report.comparisonEvent ? "w-64" : "w-36 rounded-tr-lg"
                        } dark:text-white bg-zinc-300 text-zinc-800 dark:bg-zinc-800 text-center flex items-center border-r dark:border-white/10 bg-dark-primary justify-center border-t text-white font-semibold`}
                      >
                        {report.comparisonEvent ? (
                          <div className="flex text-center items-center px-3">
                            <div className="w-1/3">
                              {report.comparisonEvent.eventName}
                            </div>
                            <div className="w-1/3">%</div>
                            <div className="w-1/3">{selectedEvent.name}</div>
                          </div>
                        ) : (
                          "Cumulative Total"
                        )}
                      </div>
                    </div>
                    {report.baseEvent.map(
                      (month: MonthlyReport, monthIndex: number) => (
                        <div className="w-full">
                          {" "}
                          <div
                            key={month.month}
                            className={`inline-flex mb-4 dark:bg-dark-primary dark:text-gray-300 dark:border-white/10 border-l border-t border-b rounded-l-lg overflow-hidden ${
                              monthIndex === report.baseEvent.length - 1
                                ? "rounded-b-lg"
                                : ""
                            }`}
                          >
                            <div className="w-40 dark:text-white font-semibold">
                              <div className="py-2 pl-3">{month.month}</div>
                            </div>
                            <div className="">
                              {month.weeks.map((week: WeeklyReport, index) => {
                                // Determine if the week is in the past or current week
                                const firstDayOfWeek = Object.keys(
                                  week.days
                                )[0];
                                const isCurrentOrPastWeek =
                                  firstDayOfWeek &&
                                  (isPast(parseISO(firstDayOfWeek)) ||
                                    isSameWeek(
                                      parseISO(firstDayOfWeek),
                                      today
                                    ));

                                return (
                                  <div
                                    key={week.week}
                                    className={`flex dark:border-white/5 ${
                                      index === month.weeks.length - 1
                                        ? ""
                                        : "border-b"
                                    }`}
                                  >
                                    <div className="w-24 text-center py-2 dark:border-white/10 border-l">
                                      {week.weeksLeft}
                                    </div>
                                    <div
                                      className={`text-center dark:border-white/10 border-x ${
                                        report.comparisonEvent ? "w-64" : "w-36"
                                      }`}
                                    >
                                      {report.comparisonEvent ? (
                                        <div className="flex h-full text-center">
                                          <div className="w-1/3 flex items-center justify-center">
                                            {week.comparisonOnSale
                                              ? week.comparisonWeekTotal
                                              : "x"}
                                          </div>
                                          <div className="w-1/3 border-x border-white/10 flex items-center justify-center">
                                            {isCurrentOrPastWeek
                                              ? week.comparisonOnSale
                                                ? calculatePercentageChange(
                                                    week.weekTotal,
                                                    week.comparisonWeekTotal
                                                  )
                                                : "x"
                                              : ""}
                                          </div>
                                          <div className="w-1/3 flex items-center justify-center">
                                            {isCurrentOrPastWeek
                                              ? week.weekTotal
                                              : ""}
                                          </div>
                                        </div>
                                      ) : isCurrentOrPastWeek ? (
                                        <div className="flex items-center justify-center h-full">
                                          {week.weekTotal}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="flex">
                                      {Object.entries(week.days).map(
                                        ([date, day]) => (
                                          <div
                                            key={date}
                                            className="text-center py-2 dark:border-white/10 border-r cursor-pointer"
                                          >
                                            {day.onSale ? (
                                              <div
                                                onClick={() => {
                                                  setDayToView({
                                                    date,
                                                    day,
                                                  });
                                                  setDayViewerOpen(true);
                                                }}
                                                className={`has-tooltip w-16 ${
                                                  report.comparisonEvent &&
                                                  parseISO(date) <= today
                                                    ? "cursor-pointer"
                                                    : ""
                                                }`}
                                              >
                                                {/* We only want to give historical information about tickets on the days that have already happened */}
                                                {report.comparisonEvent &&
                                                  parseISO(date) <= today && (
                                                    <span className="px-2 tooltip bg-white text-zinc-800 rounded shadow-lg p-1 dark:bg-dark-secondary border dark:border-white/10 -mt-8 text-sm text-gray-300">
                                                      <span className="font-bold dark:text-white">
                                                        {
                                                          report.comparisonEvent
                                                            .eventName
                                                        }
                                                        :
                                                      </span>{" "}
                                                      <span className="dark:text-zinc-300">
                                                        {
                                                          report
                                                            .comparisonEvent[
                                                            getDayAndMonthFromDate(
                                                              date
                                                            )
                                                          ].orders.length
                                                        }
                                                      </span>
                                                    </span>
                                                  )}
                                                {parseISO(date) <= today
                                                  ? day.orders.length
                                                  : ""}
                                              </div>
                                            ) : (
                                              <div className="w-16 text-center">
                                                x
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className={`text-center border-r dark:border-white/10 ${
                                        report.comparisonEvent ? "w-64" : "w-36"
                                      } ${
                                        monthIndex ===
                                          report.baseEvent.length - 1 &&
                                        index === month.weeks.length - 1
                                          ? "rounded-br-lg"
                                          : ""
                                      }`}
                                    >
                                      {report.comparisonEvent ? (
                                        <div className="flex text-center w-full h-full">
                                          <div className="w-1/3 flex items-center justify-center flex items-center justify-center">
                                            {week.comparisonCumulativeTotal}
                                          </div>
                                          <div className="w-1/3 border-x border-white/10 flex items-center justify-center">
                                            {isCurrentOrPastWeek
                                              ? calculatePercentageChange(
                                                  week.cumulativeTotal,
                                                  week.comparisonCumulativeTotal
                                                )
                                              : ""}
                                          </div>
                                          <div className="w-1/3 flex items-center justify-center">
                                            {isCurrentOrPastWeek
                                              ? week.cumulativeTotal
                                              : ""}
                                          </div>
                                        </div>
                                      ) : isCurrentOrPastWeek ? (
                                        <div className="flex items-center justify-center h-full">
                                          {week.cumulativeTotal}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <>No data to generate this report yet</>
              )}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};
