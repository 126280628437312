// @ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PaymentPlanSchedule } from "../components/PaymentPlanSchedule";
import { OrderBase } from "../models/Order";
import { getOrderByGatewayId } from "../queries";
import { PortalLayout } from "../components/portal/layouts/PortalLayout";
import Lottie from "lottie-react";
import successAnimation from "../assets/successAnimation.json";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../store/cart";
import { Nav } from "../components/portal/Nav";
import { getFeatureFlag } from "../utils/feature-flags";
import { State } from "../store/store";
import { format, parse, parseISO } from "date-fns";
import JsxParser from "react-jsx-parser";
import { NewEventDetails } from "@/components/portal/NewEventDetails";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { AddToCalendarButton } from "@/components/portal/AddToCalendarButton";
import { start } from "repl";

export const Complete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const event = useSelector((state: State) => state.cart.event);
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState<OrderBase>();
  const portalNavEnabled = getFeatureFlag("PORTAL_NAV_ENABLED");
  const addonsEnabled = getFeatureFlag("ADDONS_ENABLED");

  useEffect(() => {
    (async function () {
      const paymentIntentClientSecret = searchParams.get(
        "payment_intent_client_secret"
      );
      if (paymentIntentClientSecret) {
        let response = await getOrderByGatewayId(paymentIntentClientSecret);
        setOrder(response.data);
      } else {
        // Check and see if it's a Setup Intent, if it is we need to charge the Payment Plan now
        const setupIntent = searchParams.get("setup_intent");
        if (setupIntent) {
          let response = await getOrderByGatewayId(setupIntent);
          setOrder(response.data);
        } else {
          navigate("/");
        }
      }
    })();
  }, []);

  const startDate = event ? parseISO(event.startDate) : null;
  const endDate = event ? parseISO(event.endDate) : null;

  return (
    <PortalLayout>
      <div className="relative lg:mt-0 min-h-screen px-2">
        <div className="flex min-h-full flex-1 flex-col justify-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-xl mb-4">
            {order && event ? (
              <>
                <div className="lg:mt-0 relative mx-auto lg:grid-cols-2">
                  <div className="pt-6 pb-36 sm:px-6 lg:px-0 lg:pb-16">
                    <div className="mx-auto max-w-lg lg:max-w-none">
                      <div className="flex items-center">
                        <div className="h-12 w-12">
                          <Lottie
                            animationData={successAnimation}
                            loop={false}
                          />
                        </div>
                        <div className="ml-3">
                          <h2 className="text-lg font-medium text-gray-900 -mb-1.5 dark:text-white">
                            Thanks for your order!{" "}
                          </h2>
                          <small className="dark:text-gray-400">
                            #{order.uuid}
                          </small>
                        </div>
                      </div>
                      <div className="border-t border-gray-200 dark:border-white/10 pt-6 mt-6">
                        <div>
                          <span className="uppercase text-sm font-bold dark:text-white">
                            You're going to
                          </span>
                          <div className="mt-4">
                            <NewEventDetails event={event} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {event && (
                      <div className="mt-6 -mb-2.5">
                        <AddToCalendarButton event={event} order={order} />
                      </div>
                    )}

                    {/* {startDate && endDate && (
                      <AddToCalendarButton
                        name={event.name}
                        options={["Apple", "Google"]}
                        location={event.location}
                        startDate={startDate.getDate().toString()}
                        endDate={startDate.getDate().toString()}
                        startTime={startDate.getTime().toString()}
                        endTime={endDate.getTime().toString()}
                        timeZone="Europe/Dublin"
                      ></AddToCalendarButton>
                    )} */}

                    {/* <AddToCalendarButton
                      name={event.name}
                      options={["Apple", "Google"]}
                      location={event.location}
                      startDate={format(startDate, "yyyy-MM-dd")}
                      endDate={format(endDate, "yyyy-MM-dd")}
                      startTime={format(startDate, "HH:mm")}
                      endTime={format(endDate, "HH:mm")}
                      timeZone="Europe/Dublin"
                    ></AddToCalendarButton> */}

                    {/* <div className="flex mt-4 grid grid-flow-col justify-stretch mt-8">
                      <div className="text-5xl text-center">🎟️</div>
                      <div className="flex items-center justify-center">
                        <ArrowRightIcon className="h-10 w-10 text-white" />
                      </div>
                      <div className="text-5xl text-center">📩</div>
                      <div className="flex items-center justify-center">
                        <ArrowRightIcon className="h-10 w-10 text-white" />
                      </div>
                      <div className="text-5xl text-center">🤘</div>
                    </div> */}

                    <div className="mt-8">
                      <div className="mt-7">
                        {order.monthlyTotal ? (
                          <p className="text-sm text-gray-700 dark:text-gray-300">
                            Thank you for your order and for signing up to our
                            Instalment Plan. We will bill your first payment in
                            the plan shortly and you will receive an email to{" "}
                            <span className="underline">
                              {order.emailAddress}
                            </span>{" "}
                            once it has gone through.
                            <br />
                            <br />
                            Please allow a couple of minutes for this to come
                            through. If you can't see it, make sure you check
                            your junk. We will continue to send you payment
                            reminders and confirmations throughout the course of
                            the payment plan. Once the final payment has been
                            made, we will issue you with your ticket through
                            email.
                          </p>
                        ) : (
                          <p className="text-sm text-gray-700 dark:text-gray-300">
                            Thank you for your order. We have sent a
                            confirmation of your payment and your tickets to{" "}
                            <span className="underline">
                              {order.emailAddress}
                            </span>
                            . Please allow a couple of minutes for this to come
                            through. If you can't see your ticket, make sure you
                            check your junk.
                            <br />
                            {addonsEnabled && (
                              <>
                                <br />
                                If you purchased add-ons, they will be confirmed
                                in the email we sent. Your purchased add-ons are
                                linked to this order and will be available to
                                you anytime your ticket is scanned at the event.
                                <br />
                                <br />
                                If you would like to manage your order or
                                purchase further add-ons please{" "}
                                <span
                                  className="underline cursor-pointer"
                                  onClick={() => {
                                    setTimeout(() => {
                                      dispatch(
                                        cartActions.SetManageOrderModalIsOpen({
                                          manageOrderModalIsOpen: true,
                                        })
                                      );
                                    }, 400);
                                    navigate("/");
                                  }}
                                >
                                  click here
                                </span>
                                , or click the link in the order confirmation we
                                sent to your email.
                              </>
                            )}
                          </p>
                        )}
                      </div>

                      {/* <div className="mt-6 flex">
                        <div className="w-1/2 -scroll-ml-10">
                          <p className="text-sm font-bold">Event Location</p>
                          <p className="text-sm text-gray-700">
                            {event.location}
                          </p>
                        </div>
                        <div className="w-1/2">
                          <p className="text-sm font-bold">Event Date</p>
                          <p className="text-sm text-gray-700">
                            <>
                              {format(
                                new Date(event.startDate),
                                "EEEE, dd LLLL yyyy 'at' h:mm aaaa"
                              )}
                            </>
                          </p>
                        </div>
                      </div> */}

                      {!order.monthlyTotal && (
                        <div className="mt-8 flex">
                          <div className="w-1/2">
                            <p className="text-sm font-bold dark:text-white">
                              {order.attendees} ticket
                              {order.attendees > 1 ? <>s</> : <></>} sent to
                            </p>
                            <p className="text-sm text-gray-700 dark:text-gray-400">
                              {order.emailAddress}
                            </p>
                          </div>
                        </div>
                      )}

                      {order.monthlyTotal && (
                        <div className="mt-6">
                          <PaymentPlanSchedule
                            showPaymentTerms={false}
                            order={order}
                            isFirsPaymentPaid={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="h-screen flex items-center justify-center">
                <svg
                  className="animate-spin h-14 w-14 text-blue-200 -mt-20"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </PortalLayout>
  );
};
