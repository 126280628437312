import { format, parseISO } from "date-fns";

export const dateToPortalDisplay = (date: Date) => {
  return format(date, "eee dd MMM yyyy, hh:mm aaa");
};

export const formatDateWithoutOrdinal = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, "MMMM d yyyy");
};
