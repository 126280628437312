import { formatNumberToMoney } from "@/utils/cart";
import { Field, useFormikContext } from "formik";
import { format } from "path";
import { useState } from "react";

interface Props {
  name: string;
}

export const NumberOfMonthsField = ({ name }: Props) => {
  const { errors, values, setFieldValue } = useFormikContext<any>();
  const [error, setError] = useState<any>("");
  const fieldProps = {
    className: `${
      error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
    } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`,
  };

  return (
    <div className="flex items-center">
      <div className="">
        <div className="relative rounded-md shadow-sm w-28">
          {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">&euro;</span>
        </div> */}
          <Field
            name={name}
            className={`${fieldProps.className} pr-11`}
            type="number"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span id="price-currency" className="text-gray-500 sm:text-sm">
              months
            </span>
          </div>
        </div>
      </div>

      {values.fees && values.numberOfMonths && values.price && (
        <div className="flex-1 flex w-1/2 ml-6 -mt-0.5">
          <div>
            <h4 className="font-medium text-gray-900 dark:text-white">
              Total per month
            </h4>
            <p className="text-gray-500 text-xs">
              Billed at the start of the period
            </p>
          </div>
          <div className="flex justify-end flex-1 items-center">
            <div className="text-right">
              <h4 className="dark:text-white font-medium">
                {formatNumberToMoney(
                  (values.price + (values.passFees ? values.fees : 0)) /
                    values.numberOfMonths
                )}
              </h4>
              <p className="text-gray-500 text-xs">per month</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
