import {
  FormField as FormFieldModel,
  FormFieldCategory,
} from "../../../models/form";
import { useFormStore } from "../../../store/form";

interface Props {
  // optional ID for if this is a selected field
  id?: number;
  onClick?: () => void;
  category: FormFieldCategory;
  field: FormFieldModel;
}

export const FormField = ({ id, onClick, category, field }: Props) => {
  const { activeField } = useFormStore();

  const isThisFieldActive = id && activeField?.id === id;

  return (
    <div
      className={`flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-primary pl-2 pr-3 py-2 rounded-md transition duration-200 ease-in-out ${
        isThisFieldActive
          ? "bg-gray-100 dark:bg-dark-primary hover:bg-dark-primary "
          : ""
      }`}
      onClick={() => (onClick ? onClick() : null)}
    >
      <div
        className={`p-1 mr-2 items-center justify-center rounded-md ${category.colour}`}
      >
        <field.icon className="w-4 h-4 text-gray-900" />
      </div>
      <div>
        <h3 className="text-sm text-gray-600 dark:text-gray-500 dark:text-white">
          {field.label}
        </h3>
      </div>
    </div>
  );
};
