import { SelectedContentField, SelectedField } from "@/models/form";
import { useFormikContext } from "formik";

interface Props {
  field: SelectedField;
}

export const ContentField = ({ field }: Props) => {
  const { values } = useFormikContext();

  const getFieldIndex = (fieldId: number) => {
    return values.fields.findIndex((field_: any) => field_.id === fieldId);
  };

  console.log("vals are");
  console.log(values);
  console.log(field);

  const fieldIndex = getFieldIndex(field.id);

  console.log(fieldIndex);

  return <div className="text-gray-900 dark:text-white">{field.content}</div>;
};
