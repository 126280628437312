import {
  SelectedField,
  SelectedFormField,
  SelectedContentField,
} from "../../../models/form";
import { ChoiceField } from "./fields/ChoiceField";
import { ContactField } from "./fields/ContactField";
import { ContentField } from "./fields/ContentField";
import { NumberField } from "./fields/NumberField";
import { TextField } from "./fields/TextField";
import { TimeField } from "./fields/TimeField";
import { useFormikContext } from "formik";

const renderField = (
  field: SelectedField,
  index: number,
  inFormBuilder?: boolean
) => {
  // debugger;
  if ((field as SelectedFormField).category) {
    const formField = field as SelectedFormField;
    console.log("HERERRRR");
    console.log(formField.category.name);
    switch (formField.category.name) {
      case "Contact":
        return (
          <ContactField
            inFormBuilder={inFormBuilder}
            field={formField}
            key={index}
          />
        );
      case "Number":
        return (
          <NumberField
            inFormBuilder={inFormBuilder}
            index={index}
            field={formField}
            key={index}
          />
        );
      case "Text":
        return (
          <TextField
            inFormBuilder={inFormBuilder}
            field={formField}
            key={index}
          />
        );
      case "Time":
        return (
          <TimeField
            inFormBuilder={inFormBuilder}
            field={formField}
            key={index}
          />
        );
      case "Choice":
        return <ChoiceField field={formField} key={index} />;
      case "Content":
        return <ContentField field={formField} key={index} />;
      default:
        return <div key={index}>Unknown field type</div>;
    }
  } else if ((field as SelectedContentField).content) {
    console.log("innnn connntent");
    const contentField = field as SelectedContentField;
    return <ContentField field={contentField} key={index} />;
  } else {
    return <div key={index}>Unknown field type</div>;
  }
};

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  // For when we can't rely on values to render the form (when we are facing the public, pass those in)
  formMetaInformation?: SelectedField[];
}

export const FormRenderer = ({ formMetaInformation, inFormBuilder }: Props) => {
  const { values } = useFormikContext<{ fields: SelectedField[] }>();

  const renderValues = (values: any) => {
    return values.fields.map((field, index) => (
      <div key={index} className="my-4">
        {renderField(field, index, inFormBuilder)}
        {(field as SelectedFormField).helpText && (
          <p id="email-description" className="mt-2 text-sm text-gray-500">
            {(field as SelectedFormField).helpText}
          </p>
        )}
      </div>
    ));
  };

  return (
    <div className="w-full p-4 -mt-4">
      {formMetaInformation
        ? renderValues(formMetaInformation)
        : renderValues(values)}
    </div>
  );
};
