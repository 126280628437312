import { useFormikContext } from "formik";
import { Checkbox } from "./Checkbox";
import { Switch } from "./Switch";

export const OrganisationAdmin = () => {
  const { values } = useFormikContext<{ isSuperuser: boolean }>();
  return (
    <>
      <Switch name={`isSuperuser`} checked={values.isSuperuser} />
      <p id="email-description" className="mt-2 text-sm text-gray-500">
        If enabled, the user will have all permissions and the below permissions
        will be ignored.
      </p>
    </>
  );
};
