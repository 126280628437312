import axios, { Axios, AxiosResponse } from "axios";
import {
  DashboardOrder,
  DashboardOrderResponse,
  DashboardTicket,
  PaginatedResponse,
  RecentOrder,
  RefreshResponse,
  SignInRequest,
  SignInResponse,
  Statistics,
  Event,
  Organisation,
  User,
  UpdatePasswordRequest,
  Permission,
  Group,
} from "./models/Dashboard";
import { ACCESS_TOKEN_KEY } from "./pages/protected-route";
import { VelocityReport } from "./models/Reports";
import { FormRequest, FormResponse } from "./models/form";

axios.defaults.withCredentials = true;

export const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const getAllTickets = (): Promise<
  AxiosResponse<Array<DashboardTicket>>
> => {
  return axios.get(`${BASE_URL}/dashboard/tickets/all`);
};

export const getTickets = (
  page?: number
): Promise<AxiosResponse<PaginatedResponse<DashboardTicket>>> => {
  return axios.get(
    `${BASE_URL}/dashboard/tickets${page ? "?page=" + page : ""}`
  );
};

export const getTicketSales = (): Promise<
  AxiosResponse<Array<DashboardTicket>>
> => {
  return axios.get(`${BASE_URL}/dashboard/tickets/sales`);
};

export const getStatistics = (): // eventId: any
Promise<AxiosResponse<Statistics>> => {
  // const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return axios.get(`${BASE_URL}/dashboard/statistics`);
};

export const getRecentOrders = (): Promise<
  AxiosResponse<Array<RecentOrder>>
> => {
  return axios.get(`${BASE_URL}/dashboard/orders/recent`);
};

export const getOrders = (
  page?: number
): Promise<AxiosResponse<PaginatedResponse<DashboardOrder>>> => {
  return axios.get(
    `${BASE_URL}/dashboard/orders${page ? "?page=" + page : ""}`
  );
};

export const getOrder = (
  orderId: string
): Promise<AxiosResponse<DashboardOrder>> => {
  return axios.get(`${BASE_URL}/dashboard/orders/${orderId}`);
};

export const signIn = (
  signInRequest: SignInRequest
): Promise<AxiosResponse<SignInResponse>> => {
  return axios.post(`${BASE_URL}/token`, signInRequest);
};

export const refreshAccessToken = (
  refreshToken: string
): Promise<AxiosResponse<RefreshResponse>> => {
  return axios.post(`${BASE_URL}/token/refresh`, {
    refresh: refreshToken,
  });
};

export const getEvents = (): Promise<AxiosResponse<Array<Event>>> => {
  return axios.get(`${BASE_URL}/dashboard/events`);
};

export const getOrganisation = (): Promise<AxiosResponse<Organisation>> => {
  return axios.get(`${BASE_URL}/dashboard/organisation`);
};

export const getOrganisations = (): Promise<AxiosResponse<Organisation>> => {
  return axios.get(`${BASE_URL}/dashboard/organisations`);
};

export const getMe = (): Promise<AxiosResponse<User>> => {
  return axios.get(`${BASE_URL}/dashboard/me`);
};

export const updateMe = (user: User): Promise<AxiosResponse<User>> => {
  return axios.put(`${BASE_URL}/dashboard/me`, user);
};

export const updateOrder = (
  orderId: number,
  order: any
): Promise<AxiosResponse<DashboardOrder>> => {
  return axios.patch(`${BASE_URL}/dashboard/orders/${orderId}`, order);
};

export const updatePassword = (
  updatePasswordRequest: UpdatePasswordRequest
): Promise<AxiosResponse> => {
  return axios.patch(`${BASE_URL}/dashboard/me`, updatePasswordRequest);
};

export const getOrderEmails = (recipient: string) => {
  return axios.get(`${BASE_URL}/dashboard/emails?recipient=${recipient}`);
};

export const resendOrderConfirmation = (
  orderUUID: string
): Promise<AxiosResponse> => {
  return axios.get(`${BASE_URL}/dashboard/orders/${orderUUID}/email`);
};

export const downloadOrderQRCodes = (orderUUID: string): Promise<any> => {
  return axios.get(`${BASE_URL}/dashboard/orders/${orderUUID}/qrcodes`, {
    responseType: "blob",
  });
};

export const createEvent = (event: Event): Promise<AxiosResponse<Event>> => {
  return axios.post(`${BASE_URL}/dashboard/events`, event);
};

export const getPermissions = (): Promise<AxiosResponse<Array<Permission>>> => {
  return axios.get(`${BASE_URL}/dashboard/permissions`);
};

export const getAllGroups = (): Promise<AxiosResponse<Array<Group>>> => {
  return axios.get(`${BASE_URL}/dashboard/groups/all`);
};

export const checkIn = (
  item: string,
  uuid: string,
  checkOut?: boolean
): Promise<AxiosResponse> => {
  return axios.post(
    `${BASE_URL}/dashboard/${item}/${uuid}/check-in${
      checkOut ? "?check_out=true" : ""
    }`
  );
};

export const getForms = (page?: number) => {
  return axios.get(`${BASE_URL}/dashboard/forms?page=${page}`);
};

export const createForm = (
  formRequest: FormRequest
): Promise<AxiosResponse<FormResponse>> => {
  return axios.post(`${BASE_URL}/dashboard/forms`, formRequest);
};

export const updateForm = (
  id: number,
  formRequest: FormRequest
): Promise<AxiosResponse<FormResponse>> => {
  return axios.put(`${BASE_URL}/dashboard/forms/${id}`, formRequest);
};

export const getForm = (id: number): Promise<AxiosResponse<FormResponse>> => {
  return axios.get(`${BASE_URL}/dashboard/forms/${id}`);
};

export const getVelocityReport = (
  comparisonEvent?: string | null
): Promise<AxiosResponse<VelocityReport>> => {
  const params = new URLSearchParams();
  if (comparisonEvent) params.append("comparison_event", comparisonEvent);

  return axios.get(`${BASE_URL}/reporting/velocity?${params.toString()}`);
};
