import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import DatePicker from "../../../../crud/form/custom";
import { useEffect, useState } from "react";
import { SelectedField } from "../../../../models/form";
import { Field, getIn, useFormikContext } from "formik";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
}

export const TimeField = ({ inFormBuilder, field }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const type = field.field.type;
  const { errors, values } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, field.uuid) || "");
  }, [errors]);

  const getBorderColour = () => {
    if (error) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  return (
    <div className="">
      <div className="flex justify-between">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {field.name ? field.name : "Untitled"}
        </label>
        {field.required && (
          <span id="email-optional" className="text-sm leading-6 text-gray-500">
            Required
          </span>
        )}
      </div>
      <div className="flex">
        {(type === "date" || type === "dateAndTime") && (
          <div
            className={`mt-2 w-full ${type === "dateAndTime" ? "mr-4" : ""}`}
          >
            <DatePicker
              options={{
                autoHide: true,
                todayBtn: false,
                clearBtn: true,
                maxDate: new Date("2030-01-01"),
                minDate: new Date("1950-01-01"),
                theme: {
                  // todo: make dynamic based on dark mode
                  background: "bg-white",
                  todayBtn: "",
                  clearBtn: "",
                  icons: "",
                  text: "",
                  disabledText: "",
                  // todo: make dynamic based on dark mode
                  input: "bg-white",
                  inputIcon: "",
                  selected: "",
                },
                icons: {
                  prev: () => <ArrowLeftIcon className="w-6 h-6" />,
                  next: () => <ArrowRightIcon className="w-6 h-6" />,
                },
                datepickerClassNames: "top-12",
                // defaultDate: field.value ? new Date(field.value) : new Date(),
                language: "en",
              }}
              //   value={new Date(field.value)}
              //   onChange={handleDateChange}
              show={isOpen}
              setShow={setIsOpen}
            />
          </div>
        )}

        {(type === "time" || type === "dateAndTime") && (
          <div className="mt-2">
            <Field
              value={inFormBuilder ? "" : field.value}
              type="time"
              name={field.uuid}
              id={field.uuid}
              required={field.required}
              className={`${getBorderColour()} h-10 text-md pb-0.5 pt-2 items-center block dark:bg-zinc-800 dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6`}
            />
          </div>
        )}
      </div>

      {!inFormBuilder && (
        <>
          {error && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </>
      )}
    </div>
  );
};
