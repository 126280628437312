import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { DateTimePicker } from "./DateTimePicker";

export const DateTimeRangeField = () => {
  return (
    <div className="flex items-center">
      <DateTimePicker name="onSaleFrom" hasTime={true} />
      <div className="flex px-3">
        <ArrowRightIcon className="h-5 w-5 text-white stroke-2" />
      </div>
      <DateTimePicker name="onSaleTo" hasTime={true} />
    </div>
  );
};
