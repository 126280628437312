import { Event } from "@/models/Dashboard";
import { EVENT_KEY } from "@/pages/protected-route";
import { formatNumberToMoney } from "@/utils/cart";
import { Field, getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";

interface Props {
  name: string;
}

export const TicketPriceField = ({ name }: Props) => {
  const { errors, values, setFieldValue } = useFormikContext<any>();
  const value = getIn(values, name) || "";
  const [error, setError] = useState<any>("");
  const event: Event = JSON.parse(localStorage.getItem(EVENT_KEY) || "{}");

  useEffect(() => {
    setError(getIn(errors, name) || "");
  }, [errors]);

  console.log(values);

  const getBorderColour = () => {
    if (error) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  useEffect(() => {
    // If the event is a percentage price
    if (event.platformFeePercentage) {
      setFieldValue("fees", value * parseFloat(event.platformFeePercentage));
    }
    // If the event is a fixed price
    else if (event.platformFee) {
      setFieldValue("fees", parseFloat(event.platformFee));
    }
  }, [value]);

  const fieldProps = {
    className: `${
      error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
    } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`,
  };

  return (
    <div>
      <div className="relative rounded-md shadow-sm w-40">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">&euro;</span>
        </div>
        <Field
          name={name}
          className={`${fieldProps.className} pr-11 pl-6`}
          type="number"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span id="price-currency" className="text-gray-500 sm:text-sm">
            EUR
          </span>
        </div>
      </div>

      {value && (
        <>
          <div>
            <fieldset aria-label="Plan">
              <div className="space-y-5">
                <div className="relative flex items-start mt-5">
                  <div className="flex h-6 items-center">
                    <input
                      checked={!values.passFees}
                      id="includeFees"
                      name="handleFees"
                      type="radio"
                      aria-describedby="includeFees-description"
                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                      onChange={(e) =>
                        setFieldValue("passFees", !e.target.checked)
                      }
                    />
                  </div>
                  <div className="ml-3 text-sm/6">
                    <label
                      htmlFor="includeFees"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Include{" "}
                      <svg
                        id="Livello_2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 648.3 150.34"
                        className="h-3 -mt-0.5 ml-0.5 mr-1 inline-block fill-white"
                      >
                        <g id="Livello_1-2">
                          <rect
                            x="111.99"
                            y="38.89"
                            width="33.07"
                            height="109.44"
                          />
                          <path d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z" />
                          <polygon points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89" />
                          <path d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z" />
                          <path d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z" />
                          <path d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z" />
                          <polygon points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91" />
                        </g>
                      </svg>{" "}
                      fees in price{" "}
                      <span className="ml-1 inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-500/20">
                        Recommended
                      </span>
                    </label>
                    <p id="includeFees-description" className="text-gray-500">
                      No fee will be added to the ticket price, and you will
                      receive
                      <span className="font-medium ml-1">
                        {formatNumberToMoney(value - values.fees)} (
                        {formatNumberToMoney(parseFloat(value))} -{" "}
                        {formatNumberToMoney(values.fees)})
                      </span>{" "}
                      per ticket.
                    </p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      checked={values.passFees}
                      id="passFees"
                      name="handleFees"
                      type="radio"
                      aria-describedby="passFees-description"
                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                      onChange={(e) =>
                        setFieldValue("passFees", e.target.checked)
                      }
                    />
                  </div>
                  <div className="ml-3 text-sm/6">
                    <label
                      htmlFor="passFees"
                      className="font-medium text-gray-900 dark:text-white"
                    >
                      Pass{" "}
                      <svg
                        id="Livello_2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 648.3 150.34"
                        className="h-3 -mt-0.5 ml-0.5 mr-1 inline-block fill-white"
                      >
                        <g id="Livello_1-2">
                          <rect
                            x="111.99"
                            y="38.89"
                            width="33.07"
                            height="109.44"
                          />
                          <path d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z" />
                          <polygon points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89" />
                          <path d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z" />
                          <path d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z" />
                          <path d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z" />
                          <polygon points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91" />
                        </g>
                      </svg>
                      fees on to buyer
                    </label>
                    <p id="passFees-description" className="text-gray-500">
                      A fee of {formatNumberToMoney(values.fees)} will be added
                      to the ticket price, and you will will receive the full
                      ticket price.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div className="mt-4">
            <h4 className="font-medium text-sm text-gray-900 dark:text-white">
              Buyer total:{" "}
              <span className="text-gray-400 ml-1">
                {formatNumberToMoney(
                  values.passFees ? parseFloat(value) + values.fees : value
                )}
              </span>
            </h4>
          </div>
        </>
      )}
    </div>
  );
};
