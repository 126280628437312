import { useFormStore } from "@/store/form";
import { CheckIcon } from "@heroicons/react/20/solid";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export const FormPublishedModal = () => {
  const { form } = useFormStore();
  const link = `https://fuinneamh.ticketr.events/${form.uuid}`;
  const [buttonText, setButtonText] = useState("Copy link");

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setButtonText("Copied!");
      setTimeout(() => {
        setButtonText("Copy link");
      }, 2000);
    });
  };

  return (
    <>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-500/10">
          <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-medium leading-6 text-gray-900 dark:text-white text-xl">
            Your form has been published!
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              You can view your form below
            </p>

            <div className="mt-4 mb-2 flex items-center justify-center">
              <div className="relative w-3/4">
                <input
                  id="search"
                  name="search"
                  type="text"
                  value={link}
                  readOnly
                  className="dark:ring-white/5 ring-gray-300 block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <button
                    type="button"
                    onClick={handleCopyLink}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                  >
                    {buttonText === "Copy link" && (
                      <PaperClipIcon className="h-4 w-4 mr-1" />
                    )}
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
