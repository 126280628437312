import { Event } from "@/models/Cart";
import { OrderBase } from "@/models/Order";
import { Menu } from "@headlessui/react";
import { format, parseISO } from "date-fns";
import { ChevronDownIcon } from "lucide-react";

function generateCalendarLinks(event: any) {
  const startDate = format(event.startDate, "yyyyMMdd'T'HHmmss");
  const endDate = format(event.endDate, "yyyyMMdd'T'HHmmss");

  // Google Calendar link
  const googleLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    event.name
  )}&dates=${startDate}/${endDate}&details=${encodeURIComponent(
    event.description || ""
  )}&location=${encodeURIComponent(event.location || "")}`;

  // .ics file content for Apple Calendar & Microsoft (Outlook)
  const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${event.name}
DTSTART:${startDate}
DTEND:${endDate}
DESCRIPTION:${event.description || ""}
LOCATION:${event.location || ""}
END:VEVENT
END:VCALENDAR`;

  return { googleLink, icsContent };
}

function downloadICSFile(content: string, fileName: string) {
  const blob = new Blob([content], { type: "text/calendar" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

interface Props {
  event: Event;
  order: OrderBase;
}

export const AddToCalendarButton = ({ event, order }: Props) => {
  const startDate = event ? parseISO(event.startDate) : null;
  const endDate = event ? parseISO(event.endDate) : null;

  const description = `Order ID: #${order.uuid}\nTickets purchased on https://ticketr.events`;

  // Generate the links and content
  const { googleLink, icsContent } = generateCalendarLinks({
    name: event.name,
    startDate,
    endDate,
    description,
    location: event.location,
  });

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-dark-secondary dark:text-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 hover:bg-gray-50 dark:hover:bg-zinc-800">
          Save to Calendar
          <ChevronDownIcon
            aria-hidden="true"
            className="size-5 text-gray-400"
          />
        </Menu.Button>
      </div>

      <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-dark-secondary shadow-lg ring-1 ring-black/5 transition focus:outline-none">
        <div className="py-1">
          {/* Apple Calendar */}
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex w-full items-center px-4 py-2 text-sm dark:text-gray-400 dark:hover:bg-zinc-800`}
                onClick={() => downloadICSFile(icsContent, `${event.name}.ics`)}
              >
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    className="h-5 w-5 dark:fill-gray-400"
                  >
                    <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z" />
                  </svg>
                </span>{" "}
                <span className="mt-0.5">Apple (iPhone etc.)</span>
              </button>
            )}
          </Menu.Item>

          {/* Google Calendar */}
          <Menu.Item>
            {({ active }) => (
              <a
                href={googleLink}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex w-full items-center px-4 py-2 text-sm dark:text-gray-400 dark:hover:bg-zinc-800`}
              >
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    className="h-5 w-5 dark:fill-gray-400"
                  >
                    <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z" />
                  </svg>
                </span>{" "}
                <span className="mt-0.5">Google (Android etc.)</span>
              </a>
            )}
          </Menu.Item>

          {/* Microsoft Outlook */}
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex w-full items-center px-4 py-2 text-sm dark:text-gray-400 dark:hover:bg-zinc-800`}
                onClick={() => downloadICSFile(icsContent, `${event.name}.ics`)}
              >
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    className="h-5 w-5 dark:fill-gray-400"
                  >
                    {" "}
                    <path d="M 5 4 C 4.448 4 4 4.447 4 5 L 4 24 L 24 24 L 24 4 L 5 4 z M 26 4 L 26 24 L 46 24 L 46 5 C 46 4.447 45.552 4 45 4 L 26 4 z M 4 26 L 4 45 C 4 45.553 4.448 46 5 46 L 24 46 L 24 26 L 4 26 z M 26 26 L 26 46 L 45 46 C 45.552 46 46 45.553 46 45 L 46 26 L 26 26 z" />
                  </svg>
                </span>{" "}
                <span className="mt-0.5">Microsoft Outlook</span>
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};
