import { Field, useFormikContext } from "formik";
import { useState } from "react";

interface Props {
  name: string;
}

export const TicketQuantityAvailableField = ({ name }: Props) => {
  const { errors, values, setFieldValue } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  const fieldProps = {
    className: `${
      error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
    } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`,
  };

  const formatNumber = (value: string) => {
    console.log("hhheeeeere");
    console.log(value);
    return new Intl.NumberFormat().format(Number(value.replace(/,/g, "")));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatNumber(e.target.value);
    setFieldValue(name, formattedValue.replace(/,/g, ""));
  };

  return (
    <div className="">
      <div className="relative rounded-md shadow-sm w-32">
        <Field
          name={name}
          className={`${fieldProps.className} pr-11`}
          type="text"
          value={values[name] ? formatNumber(values[name].toString()) : ""}
          onChange={handleChange}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span id="price-currency" className="text-gray-500 sm:text-sm">
            tickets
          </span>
        </div>
      </div>
    </div>
  );
};
