import { PortalLayout } from "@/components/portal/layouts/PortalLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { FormFieldRequest, FormResponse } from "@/models/form";
import { getForm } from "@/queries";
import { FormRenderer } from "@/components/dashboard/forms/FormRenderer";
import { Formik, FormikValues } from "formik";
import yup from "../crud/yup-extended";
import { toFrontendFormat } from "@/utils/forms";

/*
todo: 
- make sure form validation works
- handle form submissions
- show form responses on dashboard
- clone a form field
- delete a form field
- hide field based on input of other field

*/

export const Form = () => {
  const navigate = useNavigate();
  const { formId } = useParams<{ formId: string }>();

  const { data, error, isLoading } = useQuery<
    AxiosResponse<FormResponse>,
    Error
  >(
    ["form", formId],
    () => {
      if (formId) {
        return getForm(formId);
      }
      return Promise.reject(new Error("formId is undefined"));
    },
    {
      enabled: !!formId, // Only run the query if formId is available
    }
  );

  if (!formId) {
    navigate("/");
    return null;
  }

  if (isLoading) {
    return (
      <PortalLayout>
        <div className="h-screen text-white">Loading...</div>
      </PortalLayout>
    );
  }

  if (error) {
    return (
      <PortalLayout>
        <div className="h-screen text-white">Error: {error.message}</div>
      </PortalLayout>
    );
  }

  const createYupSchema = (fields: Array<FormFieldRequest>) => {
    const shape = {};
    fields.forEach((field) => {
      var schema = undefined;
      console.log(field);
      const numberFields = ["moneyField", "decimal", "number"];
      if (numberFields.includes(field.fieldType)) {
        schema = yup.number().typeError("This field must be a valid number"); // Custom error message for invalid numbers
      } else if (field.fieldType === "address") {
        schema = yup.object().shape({
          street: yup.string().required("Street is required"),
          city: yup.string().required("City is required"),
          postcode: yup.string().required("Postcode is required"),
          country: yup.string().required("Country is required"),
        });
      } else if (field.fieldType === "time") {
        schema = yup
          .string()
          .matches(
            /^([01]\d|2[0-3]):([0-5]\d)$/,
            "This field must be a valid 24-hour time in the format HH:MM"
          );
      } else {
        schema = yup.string();
      }
      if (field.required) {
        schema = schema.required("This field is required");
      }
      if (field.fieldType === "email") {
        schema = schema.email("Invalid email address");
      }
      if (field.fieldType === "multipleChoice") {
        schema = yup.string().oneOf(
          field.options.map((option) => option.name),
          "Invalid choice"
        );
      }

      // number fields
      // address field
      shape[field.uuid] = schema;
    });
    return yup.object().shape(shape);
  };

  const toClientFormat = (form: FormResponse) => {
    return {
      id: form.id,
      name: form.name,
      fields: form.fields.map((field) => {
        return {
          [form.uuid]: "",
        };
      }),
    };
  };

  const handleSubmit = (values: FormikValues) => {
    // Function to filter and extract UUID keys with their values
    const isUUID = (key: string) => /^[0-9a-fA-F\-]{36}$/.test(key);

    const result = Object.entries(values)
      .filter(([key]) => isUUID(key) || key === "id") // Include UUID keys and `id`
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, any>);

    console.log(result);
  };

  /*

  So on the backend the initial values and validator is for actually creating the form, for
  here it's different, the values need to just be [form.uuid]: "". The actual fields need
  to come from somewhere else
  */

  return (
    <PortalLayout>
      <div className="h-screen text-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl p-4">
          <h1 className="text-xl text-white">{data?.data.name}</h1>

          {console.log(
            createYupSchema(data?.data.fields as Array<FormFieldRequest>)
          )}
          <div className="-m-4">
            <Formik
              initialValues={toClientFormat(data.data)}
              validationSchema={createYupSchema(
                data?.data.fields as Array<FormFieldRequest>
              )}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, errors, values }) => {
                return (
                  <form>
                    <FormRenderer
                      formMetaInformation={toFrontendFormat(data.data)}
                    />
                    <button
                      className="text-white bg-red-500"
                      onClick={() => handleSubmit()}
                      type="button"
                    >
                      Submit form
                    </button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
};
