/*
  Input primary responsible for:
  - email
  - phone
  - address
  - website

  fallback on just a plain old input field
*/

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { SelectedField } from "../../../../models/form";
import { Field, FieldArray, getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
}

export const ContactField = ({ inFormBuilder, field }: Props) => {
  const type = field.field.type;
  const { errors } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    console.log("here");
    console.log(field);
    console.log(errors);
    setError(getIn(errors, field.uuid) || "");
  }, [errors]);

  const getBorderColour = (fieldName?: string) => {
    if (fieldName ? error[fieldName] : error) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  console.log("in form builder:");
  console.log(inFormBuilder);

  return (
    <div className="">
      {(type === "email" || type === "phoneNumber") && (
        <div>
          <div className="flex justify-between">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              {field.name ? field.name : "Untitled"}
            </label>
            {field.required && (
              <span
                id="email-optional"
                className="text-sm leading-6 text-gray-500"
              >
                Required
              </span>
            )}
          </div>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {field.field.type === "email" ? (
                <EnvelopeIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              ) : (
                <PhoneIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              )}
            </div>
            <Field
              value={inFormBuilder ? "" : field.value}
              id={field.uuid}
              name={field.uuid}
              type={type === "email" ? "email" : "tel"}
              placeholder={
                type === "email" ? "something@mail.com" : "+353 87 1234567"
              }
              className={`block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white ${getBorderColour()}`}
            />
          </div>
        </div>
      )}

      {type === "website" && (
        <div>
          <div className="flex justify-between">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              {field.name ? field.name : "Untitled"}
            </label>
            {field.required && (
              <span
                id="email-optional"
                className="text-sm leading-6 text-gray-500"
              >
                Required
              </span>
            )}
          </div>
          <div className="mt-2">
            <div
              className={`${getBorderColour()} flex rounded-md bg-white shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 dark:bg-zinc-800`}
            >
              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-2 dark:text-white">
                https://
              </span>
              <Field
                value={inFormBuilder ? "" : field.value}
                id={field.uuid}
                name={field.uuid}
                type="text"
                placeholder="www.example.com"
                className={`${getBorderColour()} block flex-1 bg-white ring-inset ring-1 dark:bg-zinc-800 py-1.5 pl-2.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 border-0 w-full rounded-r-md dark:text-white`}
              />
            </div>
          </div>
        </div>
      )}

      {type === "address" && (
        <>
          <div className="col-span-full">
            <div className="flex justify-between mb-2 mt-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {field.name ? field.name : "Untitled"}
              </label>
              {field.required && (
                <span
                  id="email-optional"
                  className="text-sm leading-6 text-gray-500"
                >
                  Required
                </span>
              )}
            </div>
            <label
              htmlFor={`${field.uuid}.street`}
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Street
            </label>
            <div className="mt-2">
              <Field
                id={`${field.uuid}.street`}
                name={`${field.uuid}.street`}
                type="text"
                autoComplete="street-address"
                className={`${getBorderColour(
                  "street"
                )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
              />
            </div>
            {error.street && (
              <div className="mt-2 text-xs text-red-500">{error.street}</div>
            )}
          </div>

          <div className="flex mt-3">
            <div className="w-1/3 pr-2">
              <label
                htmlFor={`${field.uuid}.city`}
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                City
              </label>
              <div className="mt-2">
                <Field
                  id={`${field.uuid}.city`}
                  name={`${field.uuid}.city`}
                  type="text"
                  autoComplete="address-level2"
                  className={`${getBorderColour(
                    "city"
                  )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                />
              </div>
              {error.city && (
                <div className="mt-2 text-xs text-red-500">{error.city}</div>
              )}
            </div>

            <div className="w-1/3 pr-2 pl-2">
              <label
                htmlFor={`${field.uuid}.postcode`}
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Post code
              </label>
              <div className="mt-2">
                <Field
                  id={`${field.uuid}.postcode`}
                  name={`${field.uuid}.postcode`}
                  type="text"
                  autoComplete="postal-code"
                  className={`${getBorderColour(
                    "postcode"
                  )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                />
              </div>
              {error.postcode && (
                <div className="mt-2 text-xs text-red-500">
                  {error.postcode}
                </div>
              )}
            </div>

            {/* todo: searchable dropdown with flags for country */}
            <div className="w-1/3 pl-2">
              <label
                htmlFor={`${field.uuid}.country`}
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Country
              </label>
              <div className="mt-2">
                <Field
                  id={`${field.uuid}.country`}
                  name={`${field.uuid}.country`}
                  type="text"
                  autoComplete="country"
                  className={`${getBorderColour(
                    "country"
                  )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                />
              </div>
              {error.country && (
                <div className="mt-2 text-xs text-red-500">{error.country}</div>
              )}
            </div>
          </div>
        </>
      )}
      {!inFormBuilder && (
        <>
          {error && type != "address" && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </>
      )}
    </div>
  );
};
