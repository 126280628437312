import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { Fragment, useEffect, useState } from "react";
import { dashboardActions } from "../../../store/dashboard";
import { SkeletonLoading } from "../../../components/SkeletonLoading";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "../../../crud/form/Input";
import { ButtonSpinner } from "../../../components/ButtonSpinner";
import { set } from "date-fns";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { updatePassword } from "../../../dashboardQueries";
import { AxiosError } from "axios";
import { ViewEventButton } from "../../../components/dashboard/nav/ViewEventButton";

export const AccountSettings = () => {
  const dispatch = useDispatch();
  const me = useSelector((state: State) => state.dashboard.me);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPasswordModalOpen, setConfirmPasswordModalOpen] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  useEffect(() => {
    dispatch(dashboardActions.GetMe());
  }, []);

  const handleNameEmailSubmit = async (values: any) => {
    setIsLoading(true);
    await dispatch(dashboardActions.UpdateMe({ me: values }));
    toast.success("Account updated successfully");
    setIsLoading(false);
  };

  const handlePasswordSubmit = (values: any) => {
    if (values.password !== values.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setNewPassword(values.password);
    setConfirmPasswordModalOpen(true);
  };

  const changePassword = async () => {
    try {
      await updatePassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
    } catch (error: any) {
      if (error.response.data.newPassword) {
        if (
          error.response.data.newPassword[0] === "This password is too common."
        ) {
          setIsLoading(false);
          toast.error("This password is too common");
          return;
        }
      } else {
        setIsLoading(false);
        toast.error("Incorrect password");
        return;
      }
    }
    toast.success("Password updated successfully");
    setConfirmPasswordModalOpen(false);
  };

  return (
    <DashboardLayout
      requiredPermissions={[]}
      pageTitle="Account Settings"
      rightControls={<ViewEventButton />}
    >
      {me ? (
        <div className="mt-2">
          <Formik
            initialValues={{
              firstName: me.firstName,
              lastName: me.lastName,
              email: me.email,
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            })}
            onSubmit={handleNameEmailSubmit}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <div className="mb-5 mt-7 px-10">
                  <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    Account information
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    Your personal account information
                  </p>
                </div>

                <dl className="divide-y divide-gray-200 dark:divide-white/5 px-10 max-w-5xl">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                      Name
                    </dt>
                    <dd className="flex mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                      <div className="w-1/2 pr-2">
                        <Input
                          type="text"
                          name="firstName"
                          required={false}
                          value={values.firstName}
                        />
                      </div>
                      <div className="w-1/2 pl-2">
                        <Input
                          type="text"
                          name="lastName"
                          required={false}
                          value={values.lastName}
                        />
                      </div>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                      Email
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 w-2/3">
                      <Input
                        type="text"
                        name="email"
                        required={false}
                        value={values.email}
                      />
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-white"></dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="flex items-center inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {isLoading ? (
                          <>
                            <ButtonSpinner />
                            Processing...
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </button>
                    </dd>
                  </div>
                </dl>
              </Form>
            )}
          </Formik>

          <div className="mb-5 px-10 mt-7">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              Password
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Change your account password
            </p>
          </div>

          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={Yup.object({
              password: Yup.string().required("Required"),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), undefined], "Passwords must match")
                .required("Required"),
            })}
            onSubmit={handlePasswordSubmit}
          >
            <Form className="px-10 max-w-5xl -mt-2">
              <dl className="divide-y divide-gray-200 dark:divide-white/5">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                    Password
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                    <Input type="password" name="password" required={false} />
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                    Confirm Password
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                    <Input
                      type="password"
                      name="confirmPassword"
                      required={false}
                    />
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white"></dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="flex items-center inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {isLoading ? (
                        <>
                          <ButtonSpinner />
                          Processing...
                        </>
                      ) : (
                        <>Save password</>
                      )}
                    </button>
                  </dd>
                </div>
              </dl>
            </Form>
          </Formik>

          <Transition.Root show={confirmPasswordModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              // initialFocus={cancelButtonRef}
              onClose={setConfirmPasswordModalOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-40 bg-opacity-75 backdrop-blur-md transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div>
                        <div className="text-center">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-7 text-gray-900 dark:text-white"
                          >
                            Enter your current password to confirm
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                              In order to change your password, please enter
                              your current password
                            </p>

                            <input
                              type="password"
                              name="currentPassword"
                              id="currentPassword"
                              required
                              className="mt-4 dark:ring-white/5 ring-gray-300 block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) =>
                                setCurrentPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 flex">
                        <div className="w-1/4 pr-2">
                          <button
                            type="button"
                            onClick={() => setConfirmPasswordModalOpen(false)}
                            className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="w-3/4 pl-2">
                          <button
                            type="button"
                            onClick={() => changePassword()}
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Change password
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      ) : (
        <div className="p-10">
          <SkeletonLoading />
        </div>
      )}
    </DashboardLayout>
  );
};
