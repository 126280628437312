import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import { classNames } from "../../../../utils/styles";
import { SelectedField } from "../../../../models/form";
import { useState } from "react";
import { CheckCircle } from "lucide-react";
import { CheckIcon } from "@heroicons/react/24/outline";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
}

const YES_NO_OPTIONS = [
  { id: "yes", title: "Yes" },
  { id: "no", title: "No" },
];

const LEGAL_OPTIONS = [
  { id: "i_accept", title: "I accept" },
  { id: "i_dont_accept", title: "I don't accept" },
];

export const ChoiceField = ({ field }: Props) => {
  const type = field.field.type;
  const isMultipleChoice = type === "multipleChoice";
  const [selectedChoices, setSelectedChoices] = useState<string | string[]>(
    isMultipleChoice ? [] : ""
  );

  const handleChange = (value: string | string[]) => {
    setSelectedChoices(value);
  };

  return (
    <>
      <div className="flex justify-between">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {field.name ? field.name : "Untitled"}
        </label>
        {field.required && (
          <span id="email-optional" className="text-sm leading-6 text-gray-500">
            Required
          </span>
        )}
      </div>

      {(type === "dropdown" || type === "multipleChoice") && (
        <div className="mt-2">
          <Listbox
            value={selectedChoices}
            onChange={handleChange}
            multiple={isMultipleChoice}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button
                    className={`px-3 py-2 flex dark:ring-white/5 ring-gray-300 w-full relative cursor-default rounded-md bg-white dark:bg-zinc-800 dark:ring-zinc-700 dark:text-gray-400 p-1 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                  >
                    {isMultipleChoice
                      ? selectedChoices.length === 0
                        ? "Select multiple"
                        : (selectedChoices as string[]).join(", ")
                      : selectedChoices || "Select"}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="px-1 absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-dark-secondary dark:ring-white/5 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {field.options && (
                        <>
                          {field.options.map((option, index) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white "
                                    : "text-gray-900 dark:text-gray-400",
                                  "relative cursor-pointer select-none py-2 pl-2 pr-2 rounded-md "
                                )
                              }
                              value={option}
                            >
                              {({ selected }) => (
                                <>
                                  <div
                                    className={`block truncate flex ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    <div className="flex-1">{option}</div>
                                    <div className="flex justify-end">
                                      {selected && (
                                        <CheckIcon className="h-5 w-5 text-white" />
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </>
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      )}

      {(type === "yesNo" || type === "legal") && (
        <fieldset>
          <div className="space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0 mt-2">
            {[...(type === "yesNo" ? YES_NO_OPTIONS : LEGAL_OPTIONS)].map(
              (notificationMethod) => (
                <div key={notificationMethod.id} className="flex items-center">
                  <input
                    defaultChecked={notificationMethod.id === "email"}
                    id={notificationMethod.id}
                    name="notification-method"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor={notificationMethod.id}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900 dark:text-gray-500"
                  >
                    {notificationMethod.title}
                  </label>
                </div>
              )
            )}
          </div>
        </fieldset>
      )}
    </>
  );
};
