import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { FORM_FIELDS } from "../../../constants/form";
import { SelectedField } from "../../../models/form";
import { useFormStore } from "../../../store/form";
import { FormField } from "./FormField";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import { useState } from "react";
import { useFormikContext, FieldArray, getIn } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export const FormFieldList = () => {
  const { setActiveField } = useFormStore();
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const [fieldBeingDragged, setFieldBeingDragged] =
    useState<SelectedField | null>();

  const SortableFormField = ({
    selectedField,
  }: {
    selectedField: SelectedField;
  }) => {
    const {
      listeners,
      attributes,
      setNodeRef,
      transition,
      transform,
      active,
      isSorting,
      index,
      over,
    } = useSortable({ id: selectedField.id });

    const fieldError = getIn(errors, `fields.${index}`);

    return (
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        style={{
          transition,
          transform: transform
            ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
            : undefined,
        }}
        className="relative"
      >
        <FormField
          id={selectedField.id}
          field={selectedField.field}
          category={selectedField.category}
        />
        {fieldError && (
          <ExclamationCircleIcon
            className="absolute top-1/2 right-0 transform -translate-y-1/2 h-5 w-5 text-red-500 mr-2"
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    console.log("ended dragging");
    console.log(event);

    const { active, over } = event;
    if (over) {
      const oldIndex = values.fields.findIndex(
        (field: SelectedField) => field.id === active.id
      );
      const newIndex = values.fields.findIndex(
        (field: SelectedField) => field.id === over.id
      );

      if (oldIndex !== newIndex) {
        const updatedFields = [...values.fields];
        const [movedField] = updatedFields.splice(oldIndex, 1);
        updatedFields.splice(newIndex, 0, movedField);

        setFieldValue("fields", updatedFields);
      }
    }
    setFieldBeingDragged(null);
  };

  return (
    <>
      <DndContext
        onDragStart={(event: DragStartEvent) => {
          console.log("is here mang");
          const id = event.active.id;
          const selectedField = values.fields.find(
            (field: SelectedField) => field.id === id
          );
          if (selectedField) {
            console.log("started dragging");
            setActiveField(selectedField);
            setFieldBeingDragged(selectedField);
          }
        }}
        onDragEnd={handleDragEnd}
      >
        <FieldArray name="fields">
          {() => (
            <SortableContext items={values.fields}>
              {console.log("we here mang")}
              {console.log(values.fields)}
              {values.fields.map((selectedField: SelectedField) => (
                <SortableFormField
                  key={selectedField.id}
                  selectedField={selectedField}
                />
              ))}
            </SortableContext>
          )}
        </FieldArray>

        {fieldBeingDragged && (
          <DragOverlay>
            <div>
              <FormField
                field={fieldBeingDragged.field}
                category={fieldBeingDragged.category}
                onClick={() => alert("hey")}
              />
            </div>
          </DragOverlay>
        )}
      </DndContext>
    </>
  );
};
