import { Field, useFormikContext } from "formik";
import { useFormStore } from "../../../store/form";
import { Input } from "../../../crud/form/Input";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, Fragment, useState } from "react";
import { FORM_FIELDS, FormFieldType } from "../../../constants/form";
import { FormField } from "./FormField";
import { FormField as FormFieldModel } from "../../../models/form";
import { Switch } from "../../../crud/form/Switch";
import { Textarea } from "@/crud/form/Textarea";
import { AddChoiceOptions } from "./AddChoiceOptions";

export const FieldSettings = () => {
  const { selectedFields, activeField, setActiveField, setSelectedFields } =
    useFormStore();
  const [fieldTypeOpen, setFieldTypeOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<any>();

  const handleTypeChange = (newField: FormFieldModel, category: any) => {
    if (activeField) {
      const updatedField = {
        ...activeField,
        field: newField,
        category,
      };

      setActiveField(updatedField);

      const updatedFields = selectedFields.map((field) =>
        field.id === activeField.id ? updatedField : field
      );

      setSelectedFields(updatedFields);
    }
  };

  const getFieldIndex = (fieldId: number) => {
    return values.fields.findIndex((field: any) => field.id === activeField.id);
  };

  const handleChange = (name: string, value: any) => {
    const fieldIndex = values.fields.findIndex(
      (field: any) => field.id === activeField.id
    );
    console.log("we are here");
    console.log(fieldIndex);
    if (fieldIndex !== -1) {
      setFieldValue(`fields.${fieldIndex}.${name}`, value);
    }
  };

  const fieldIndex = getFieldIndex(activeField.id);

  return (
    <div>
      <label
        htmlFor="project-name"
        className="block text- font-medium leading-6 text-gray-900 dark:text-white mb-2"
      >
        Field Settings
      </label>
      <form>
        <Field name="id" type="hidden" />
        {activeField && activeField.hasOwnProperty("content") ? (
          <>
            <div>
              <label
                htmlFor="content"
                className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
              >
                Content
              </label>
              <textarea
                name="content"
                required={true}
                value={values.content}
                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  handleChange("content", e.target.value)
                }
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <label
                htmlFor="name"
                className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
              >
                Name
              </label>
              <Input
                name={`fields.${fieldIndex}.name`}
                type="text"
                required={true}
                value={values.fields[fieldIndex].name}
                onChange={(e) =>
                  setFieldValue(`fields.${fieldIndex}.name`, e.target.value)
                }
              />
            </div>

            <div className="mt-2">
              <label
                htmlFor="helpText"
                className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
              >
                Help text
              </label>
              <Input
                name={`fields.${fieldIndex}.helpText`}
                type="text"
                required={false}
                value={values.fields[fieldIndex].helpText}
                onChange={(e) =>
                  setFieldValue(`fields.${fieldIndex}.helpText`, e.target.value)
                }
              />
            </div>
          </>
        )}

        <div className="dark:border-white/5 border-gray-200 border-t border-b py-3 mt-4">
          <Listbox
            value={activeField?.field || {}}
            onChange={(newField: FormFieldModel) =>
              handleTypeChange(newField, FORM_FIELDS[newField.type])
            }
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button
                    className={`flex dark:ring-white/5 ring-gray-300 w-full relative cursor-default rounded-md bg-white dark:bg-dark-secondary dark:text-gray-400 p-1 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                  >
                    {activeField && (
                      <div className="w-full flex">
                        <div className="flex-1">
                          <FormField
                            field={activeField.field}
                            category={activeField.category}
                            onClick={() => {}}
                          />
                        </div>
                        <div className="flex items-center justify-center pr-1">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="py-1 px-1 absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-dark-secondary dark:ring-white/5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {Object.keys(FORM_FIELDS).map((category) => {
                        const currentCategory = FORM_FIELDS[category];
                        const fields = currentCategory.fields.filter(
                          (field: FormFieldModel) =>
                            field.type !== activeField?.field.type
                        );
                        return fields.map(
                          (field: FormFieldModel, index: number) => (
                            <FormField
                              key={index}
                              field={field}
                              category={currentCategory}
                              onClick={() =>
                                handleTypeChange(field, currentCategory)
                              }
                            />
                          )
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        {activeField && activeField.hasOwnProperty("content") ? null : (
          <div className="flex items-center mt-4">
            <div className="flex-1">
              <label
                htmlFor="required"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Required
              </label>
            </div>
            <Switch
              name={`fields.${fieldIndex}.required`}
              checked={values.required}
            />
          </div>
        )}

        {activeField &&
          (activeField.field.type === "dropdown" ||
            activeField.field.type === "multipleChoice") && (
            <AddChoiceOptions fieldIndex={fieldIndex} />
          )}
      </form>
    </div>
  );
};
