import { BellIcon } from "@heroicons/react/20/solid";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import {
  AtSymbolIcon,
  BoltIcon,
  PaperAirplaneIcon,
  PhoneIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { FORM_FIELDS } from "../../../constants/form";
import {
  Form,
  FormField,
  FormFieldCategory,
  FormRequest,
  FormResponse,
} from "../../../models/form";
import { Fragment, useEffect, useState } from "react";
import { FieldPicker } from "../../../components/dashboard/forms/FieldPicker";
import { useFormStore } from "../../../store/form";
import { FormFieldList } from "../../../components/dashboard/forms/FormFieldList";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { SettingsIcon, XIcon } from "lucide-react";
import { FieldSettings } from "../../../components/dashboard/forms/FieldSettings";
import { FormRenderer } from "../../../components/dashboard/forms/FormRenderer";
import { Formik, FormikValues } from "formik";
import yup from "../../../crud/yup-extended";
import { createForm, getForm, updateForm } from "@/dashboardQueries";
import { FormPublishedModal } from "@/components/dashboard/forms/FormPublishedModal";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { toFrontendFormat } from "@/utils/forms";

const uniqueOptions = (options: string[] | undefined) => {
  if (!Array.isArray(options)) {
    return false;
  }
  const uniqueOptionsSet = new Set(options);
  return uniqueOptionsSet.size === options.length;
};

const validationSchema = yup.object({
  fields: yup.array().of(
    yup.object({
      id: yup.number(),
      name: yup.string().required("Required"),
      // name: yup.string().when("category.name", {
      //   is: "Content",
      //   then: yup.string().notRequired(),
      //   otherwise: yup.string().required("Name is required"),
      // })
      content: yup.string(),
      helpText: yup.string(),
      required: yup.boolean(),
      options: yup
        .array()
        .of(yup.string())
        .when("category.name", {
          is: "Choice",
          then: (schema) =>
            schema
              .of(yup.string().required("Choice is required"))
              .min(1, "At least one choice is required") // Enforce minimum length of 1
              .test("unique", "Choices must be unique", uniqueOptions),
          otherwise: (schema) => schema.notRequired(),
        }),
      // .of(yup.string().required("Choice is required"))
      // .test("unique", "Choices must be unique", uniqueOptions),
    })
  ),
});

export const CreateForm = () => {
  const { formId } = useParams<{ formId: string }>();
  const {
    selectedFields,
    setSelectedFields,
    selectFieldModalOpen,
    setSelectFieldModalOpen,
    formSettingsOpen,
    setFormSettingsOpen,
    formPublishedModalOpen,
    setFormPublishedModalOpen,
    form,
    setForm,
    setActiveField,
  } = useFormStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const transformValues = (values: FormikValues) => {
    console.log("le transform");
    return {
      name: "My Form",
      fields: values.fields.map((field: any) => ({
        name: field.name,
        fieldType: field.field.type,
        required: field.required,
        helpText: field.helpText,
        options: field.options
          ? field.options.map((option: any) => ({ name: option }))
          : [],
      })),
    } as FormRequest;
  };

  // If there is a formId, then we need to set loading to be true, get the form and wait
  useEffect(() => {
    const fetchForm = async () => {
      if (formId) {
        try {
          const formResponse: AxiosResponse<FormResponse> = await getForm(
            parseInt(formId)
          );
          const frontendForm = toFrontendFormat(formResponse.data);
          setForm(toFrontendFormat(formResponse.data));
          setActiveField(frontendForm.fields[0]);
          setIsLoading(false);

          // Handle the form data as needed
        } catch (error) {
          console.error("Failed to fetch form:", error);
        }
      } else {
        setIsLoading(false);
      }
    };

    setForm(null);
    fetchForm();
  }, [formId]);

  return (
    <DashboardLayout requiredPermissions={[]} pageTitle="Create Form">
      <div className="flex min-h-full flex-col -mt-3">
        {isLoading ? (
          <div className="w-full h-screen flex items-center justify-center -mt-32">
            <svg
              className="animate-spin mr-2 h-12 w-12 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          <Formik
            initialValues={
              form
                ? form
                : {
                    fields: [],
                  }
            }
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setIsSubmitting(true);

              const transformedValues = transformValues(values);

              if (form && form.id) {
                const response: AxiosResponse<FormResponse> = await updateForm(
                  form.id,
                  transformedValues
                );
              } else {
                const response: AxiosResponse<FormResponse> = await createForm(
                  transformedValues
                );
                setForm(response.data);
              }
              setIsSubmitting(false);
              setFormPublishedModalOpen(true);
              setIsPublished(true);
            }}
          >
            {({ values, errors, handleSubmit }) => {
              // When the form values change, reset the published state
              useEffect(() => {
                setIsPublished(false);
              }, [values]);

              {
                console.log(values);
              }

              {
                console.log(errors);
              }

              return (
                <>
                  {values.fields.length > 0 ? (
                    <>
                      {/* Field picker modal */}
                      <Transition.Root
                        show={selectFieldModalOpen}
                        as={Fragment}
                      >
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={setSelectFieldModalOpen}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
                          </Transition.Child>

                          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl p-10">
                                  <div className="absolute right-0 mr-6">
                                    <XIcon
                                      className="h-6 w-6 cursor-pointer text-gray-500 -mt-4"
                                      onClick={() =>
                                        setSelectFieldModalOpen(false)
                                      }
                                    />
                                  </div>
                                  <FieldPicker
                                    onPickCallback={() =>
                                      setSelectFieldModalOpen(false)
                                    }
                                  />
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>

                      {/* Form settings */}
                      <Transition.Root show={formSettingsOpen} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={setSelectFieldModalOpen}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
                          </Transition.Child>

                          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl p-6">
                                  <div className="flex items-center mb-5">
                                    <h3 className="px-1 flex text-lg font-medium dark:text-white">
                                      Form settings
                                    </h3>
                                  </div>
                                  <div className="bg-white dark:bg-dark-secondary rounded-lg w-full h-full p-4">
                                    <dl className="space-y-6 divide-y divide-gray-100 dark:divide-white/5 border-gray-200 text-sm leading-6 pt-2">
                                      <div className="sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 dark:text-white">
                                          Language
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                          <div className="text-gray-900 dark:text-gray-400">
                                            English
                                          </div>
                                          <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                          >
                                            Update
                                          </button>
                                        </dd>
                                      </div>
                                      <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 dark:text-white">
                                          Date format
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                          <div className="text-gray-900 dark:text-gray-400">
                                            DD-MM-YYYY
                                          </div>
                                          <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                          >
                                            Update
                                          </button>
                                        </dd>
                                      </div>
                                      <div className="flex pt-6">
                                        <dt className="flex-none pr-6 font-medium text-gray-900 dark:text-white">
                                          One field per page
                                          <p
                                            id="email-description"
                                            className="text-xs text-gray-500 font-normal mt-0.5"
                                          >
                                            Instead of all fields on one page,
                                            each field will have it's own page,
                                            like a wizard
                                          </p>
                                        </dt>
                                        <dd className="flex flex-auto items-center justify-end">
                                          <Switch
                                            checked={true}
                                            onChange={() => {}}
                                            className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                                          >
                                            <span
                                              aria-hidden="true"
                                              className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                                            />
                                          </Switch>
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>

                      {/* Published Form modal */}
                      <Transition.Root
                        show={formPublishedModalOpen}
                        as={Fragment}
                      >
                        <Dialog
                          as="div"
                          className="relative z-10"
                          open={formPublishedModalOpen}
                          onClose={setSelectFieldModalOpen}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
                          </Transition.Child>

                          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl p-6">
                                  <FormPublishedModal />
                                  <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setFormPublishedModalOpen(false)
                                      }
                                      className="w-40 justify-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>

                      <div className="mx-auto flex w-full items-start gap-x-4 px-4 pt-8">
                        <aside className="top-8 hidden shrink-0 lg:block w-[16rem]">
                          {/* Left column area */}
                          <div className="relative h-screen overflow-hidden rounded-lg border dark:border-white/5 p-2">
                            <FormFieldList />
                          </div>
                        </aside>

                        <div className="flex-1 flex gap-x-4 pr-4">
                          <main className="w-1/2">
                            <div className="w-full rounded-lf border dark:border-white/5 p-2 rounded-lg flex">
                              <button
                                type="button"
                                onClick={() => setSelectFieldModalOpen(true)}
                                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                              >
                                <PlusIcon className="h-4 w-4 mr-1" />
                                Add Content
                              </button>

                              <div className="flex-1 flex justify-end">
                                {/* <button
                              type="button"
                              onClick={() => setFormSettingsOpen(true)}
                              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center mr-2"
                            >
                              <BoltIcon className="w-4 h-4" />{" "}
                              <span className="ml-1.5">Logic</span>
                            </button> */}

                                <button
                                  type="button"
                                  onClick={() => setFormSettingsOpen(true)}
                                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                >
                                  <SettingsIcon className="w-4 h-4" />
                                </button>

                                {!isPublished && (
                                  <button
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                  >
                                    {isSubmitting ? (
                                      <>
                                        <svg
                                          className="animate-spin mr-1.5 h-5 w-5 text-white dark:text-dark-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                          ></circle>
                                          <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          ></path>
                                        </svg>
                                        Publishing...
                                      </>
                                    ) : (
                                      <>Publish</>
                                    )}
                                    <PaperAirplaneIcon className="ml-1 w-4 h-4" />
                                  </button>
                                )}
                              </div>
                            </div>

                            <div className="w-full rounded-lf border dark:border-white/5 p-2 rounded-lg flex mt-4">
                              <FormRenderer
                                fields={selectedFields}
                                inFormBuilder
                              />
                            </div>
                          </main>

                          <aside className="flex-1">
                            <div className="relative h-screen overflow-hidden rounded-xl border dark:border-white/5 opacity-75 p-4">
                              <FieldSettings />
                            </div>
                          </aside>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center justify-center pt-20">
                      <div>
                        <h3 className="px-1">Choose your forms first field</h3>
                        <div className="mt-8">
                          <FieldPicker />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </DashboardLayout>
  );
};
