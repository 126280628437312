import { Field, getIn, useFormikContext } from "formik";
import { SelectedField } from "../../../../models/form";
import { useEffect, useState } from "react";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  index: number;
  field: SelectedField;
}

export const NumberField = ({ inFormBuilder, index, field }: Props) => {
  const type = field.field.type;
  const { errors, values } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, field.uuid) || "");
  }, [errors]);

  const getBorderColour = () => {
    if (error) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  return (
    <div className="">
      {type === "moneyField" && (
        <div>
          <div className="flex justify-between">
            <label
              htmlFor="money"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              {field.name ? field.name : "Untitled"}
            </label>
            {field.required && (
              <span
                id="email-optional"
                className="text-sm leading-6 text-gray-500"
              >
                Required
              </span>
            )}
          </div>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">&euro;</span>
            </div>
            <Field
              value={inFormBuilder ? "" : field.value}
              id={field.uuid}
              name={field.uuid}
              type="text"
              placeholder="0.00"
              aria-describedby="money"
              className={`${getBorderColour()} block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span id="price-currency" className="text-gray-500 sm:text-sm">
                EUR
              </span>
            </div>
          </div>
        </div>
      )}

      {(type === "decimal" || type == "number") && (
        <div>
          <div className="flex justify-between">
            <label
              htmlFor="number"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              {field.name ? field.name : "Untitled"}
            </label>
            {field.required && (
              <span id="number" className="text-sm leading-6 text-gray-500">
                Required
              </span>
            )}
          </div>
          <Field
            value={inFormBuilder ? "" : field.value}
            id={field.uuid}
            name={field.uuid}
            type="text"
            placeholder={type === "decimal" ? "0.00" : "0"}
            className={`${getBorderColour()} mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
          />
        </div>
      )}

      {!inFormBuilder && (
        <>
          {error && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </>
      )}
    </div>
  );
};
